<template>
  <div class="navbar-container d-flex content align-items-center">

   
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <ul class="nav navbar-nav" :class="windowWidth < 1001 ? 'd-none' : '' ">                
        
        <li class="nav-item">
          <b-link            
            class="nav-link" :class="activeClass('order-summary')"
            @click="goToCart"
          >            
            <span> <feather-icon icon="ShoppingCartIcon" size="16" /> Summary Order</span>
          </b-link>          
        </li>
        <li class="nav-item">
          <b-link            
        	class="nav-link" :class="activeClass('payment')"
            @click="goToCheckout"
          >
          <span> <feather-icon icon="CreditCardIcon" size="16" /> Checkout  </span>           
          </b-link>          
        </li>
      </ul>
      <b-dropdown
        :class="windowWidth > 1000 ? 'd-none' : '' "
        id="dropdown-8"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"        
        variant="outline-primary"
      >
      <template #button-content>
        <feather-icon icon="ShoppingCartIcon" size="16" /> 
      </template>                        
      <b-dropdown-item @click="goToCart" > <feather-icon icon="ShoppingCartIcon" size="16" /> Summary</b-dropdown-item>
      <b-dropdown-item  @click="goToCheckout"> <feather-icon icon="CreditCardIcon" size="16" /> Finish</b-dropdown-item>

    </b-dropdown>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      
       

      <dark-Toggler  class="d-none d-lg-block"/>

      <!-- <cart-dropdown id="cartItemVertical" v-if="$route.name != 'order-summary'" :class="windowWidth < 1300 ? 'd-none' : '' "/>       -->
      
      
      <b-dropdown
        :class="windowWidth > 1300 ? 'd-none' : '' "
        id="dropdown-8"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"        
        variant="outline-primary"
      >
      <template #button-content>
        <feather-icon icon="SettingsIcon" size="16" />
      </template>            
              
      <b-dropdown-item  @click="goToCart" > <feather-icon icon="ShoppingCartIcon" size="16" /> Cart</b-dropdown-item>
    </b-dropdown>

      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>

import {  mapState } from 'vuex'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import Ripple from 'vue-ripple-directive'
import { market } from '@/modules/shop/mixins/market'

import { showAlertMessage } from '@/helpers/helpers'


export default {
	mixins: [ market ],    
	directives: {
		Ripple,
	},
	components: {            
		DarkToggler,
		CartDropdown,
		UserDropdown,
	},
	props: {
		toggleVerticalMenuActive: {
			type: Function,
			default: () => {},
		},
	},
	computed:{
		...mapState('auth',['user','quote']),
		...mapState('appConfig',['layout','windowWidth']),    
		hasVendorCode(){      
			return  this.user?.canalventa?.dingusvendorcode || false      
		}
	},
	methods:{
		showPhrase(){
			const text = `🎉🎉 ${this.quote.phrase} `
			showAlertMessage( `Por: ${this.quote.author}`, 'BellIcon', text, 'success', 5000, 'bottom-right')      
		},
		activeClass: function (...names) {
			for (let name of names){
				if (name == this.$route.name){
				const itemActive = this.layout.skin === 'light' ? 'item-active-ligth': 'item-active-dark'
				return itemActive
				}
			}
		},
		goToCart(){
			this.$router.push({name: 'order-summary', query: { ...this.getQueryParams() } }) // getQueryParams viene desde el mixin market
		},
		goToCheckout(){
			this.$router.push({name: 'payment', query: { ...this.getQueryParams() }})
		}
	}
}
</script>


<style scoped>
  .item-active-ligth {
    /* background-color: #eceff1; */
    /* border-radius: 5px; */
    border-bottom: 2px solid black;
  }
  .item-active-ligth span {
    color: black;
    font-weight: bold;
  }
  .item-active-dark {
    /* background-color: #eceff1; */
    /* border-radius: 5px; */
    border-bottom: 2px solid white;
  }
  .item-active-dark span {
    color: white;
    font-weight: bold;
  }
</style>